import React from "react";
import { PresentationalComponent, PresentationalProps } from "./presentational";

type Props = {
  onCardSizeChanged: (size: { height?: number; width?: number }) => void;
  onDownLoadButtonClicked: () => void;
  onCardMarginChanged: (margin: number) => void;
  onIsEnableImageCropChanged: (isEnable: boolean) => void;
  onImageCropRateChanged: (value: number) => void;

  defaultCardSize: {
    height: number;
    width: number;
  };
  defaultCardMargin: number;
  isEnableImageCrop: boolean;
};

export const Menu = (props: Props) => {
  const onHeightChanged = (text: string) => {
    const height = parseInt(text, 10);
    isNaN(height)
      ? props.onCardSizeChanged({ height: 1 })
      : props.onCardSizeChanged({ height: height });
  };
  const onWidthChanged = (text: string) => {
    const width = parseInt(text, 10);
    isNaN(width)
      ? props.onCardSizeChanged({ width: 1 })
      : props.onCardSizeChanged({ width: width });
  };
  const onCardMarginChanged = (text: string) => {
    const margin = parseFloat(text);
    isNaN(margin)
      ? props.onCardMarginChanged(0)
      : props.onCardMarginChanged(margin);
  };

  const presentationalProps: PresentationalProps = {
    onHeightChanged: onHeightChanged,
    onWidthChanged: onWidthChanged,
    onCardMarginChanged: onCardMarginChanged,
    onDownloadClicked: props.onDownLoadButtonClicked,
    onIsEnableImageCropChanged: props.onIsEnableImageCropChanged,
    onImageCropsRateChanged: props.onImageCropRateChanged,
    defaultCardSize: props.defaultCardSize,
    defaultCardMargin: props.defaultCardMargin,
    isEnableImageCrop: props.isEnableImageCrop,
  };

  return <PresentationalComponent {...presentationalProps} />;
};
