class ImageCrop {
  private memCanvas: HTMLCanvasElement;
  private memCanvas2: HTMLCanvasElement;

  constructor() {
    this.memCanvas = document.createElement("canvas");
    this.memCanvas.height = 0;
    this.memCanvas.width = 0;

    this.memCanvas2 = document.createElement("canvas");
    this.memCanvas2.height = 0;
    this.memCanvas2.width = 0;
  }

  public crop = async (
    src: string,
    rate: number,
    isThumbnail: boolean = false
  ): Promise<string> => {
    return new Promise((resolve) => {
      const chara = new Image();
      chara.src = src;
      chara.onload = () => {
        const imageWidth = chara.width;
        const imageHeight = chara.height;

        const targetWidth = isThumbnail ? 320 : imageWidth;
        const targetHeight = isThumbnail
          ? (imageHeight / imageWidth) * targetWidth
          : imageHeight;

        this.memCanvas.width = targetWidth;
        this.memCanvas.height = targetHeight;

        const context = this.memCanvas.getContext("2d");
        if (context == null) {
          return resolve("");
        }

        context.drawImage(chara, 0, 0, targetWidth, targetHeight);

        resolve(this.cropImage(this.memCanvas, rate));
      };
      chara.onerror = () => {
        resolve("");
      };
    });
  };

  private binarization(image: ImageData, rate: number) {
    const thr =
      rate == 0
        ? 252
        : rate == 1
        ? 245
        : rate == 2
        ? 238
        : rate == 3
        ? 231
        : rate == 4
        ? 224
        : 255;

    /* 二値化 */
    for (var height = 0; height < image.height; height++) {
      for (var width = 0; width < image.width; width++) {
        const index = (width + height * image.width) * 4;
        const red = image.data[index + 0];
        const green = image.data[index + 1];
        const blue = image.data[index + 2];
        const alpha = image.data[index + 3];

        if (red >= thr && green >= thr && blue >= thr) {
          image.data[index + 0] = 255;
          image.data[index + 1] = 255;
          image.data[index + 2] = 255;
        } else {
          image.data[index + 0] = 0;
          image.data[index + 1] = 0;
          image.data[index + 2] = 0;
        }
      }
    }

    return image;
  }

  private cropImage(canvas: HTMLCanvasElement, rate: number): string {
    const context = canvas.getContext("2d");
    if (context == null) {
      return "";
    }

    const image = context.getImageData(0, 0, canvas.width, canvas.height);

    /* 二値化 */
    const binaryImage = this.binarization(image, rate);

    var top = 0;
    for (var height = 0; height < canvas.height; height++) {
      var count = 0;
      for (var width = 0; width < canvas.width; width++) {
        const index = (width + height * canvas.width) * 4;
        if (binaryImage.data[index] === 0) {
          count++;
        }
      }

      const isPadding = count < canvas.width / 10;
      if (isPadding) {
        top = height + 1;
      } else {
        break;
      }
    }

    var bottom = canvas.height;
    for (var height = canvas.height - 1; height >= 0; height--) {
      var count = 0;
      for (var width = 0; width < canvas.width; width++) {
        const index = (width + height * canvas.width) * 4;
        if (binaryImage.data[index] === 0) {
          count++;
        }
      }

      const isPadding = count < canvas.width / 10;
      if (isPadding) {
        bottom = height;
      } else {
        break;
      }
    }

    var left = 0;
    for (var width = 0; width < canvas.width; width++) {
      var count = 0;
      for (var height = 0; height < canvas.height; height++) {
        const index = (width + height * canvas.width) * 4;
        if (binaryImage.data[index] === 0) {
          count++;
        }
      }

      const isPadding = count < canvas.height / 10;
      if (isPadding) {
        left = width + 1;
      } else {
        break;
      }
    }

    var right = canvas.width;
    for (var width = canvas.width - 1; width >= 0; width--) {
      var count = 0;
      for (var height = 0; height < canvas.height; height++) {
        const index = (width + height * canvas.width) * 4;
        if (binaryImage.data[index] === 0) {
          count++;
        }
      }

      const isPadding = count < canvas.height / 10;
      if (isPadding) {
        right = width;
      } else {
        break;
      }
    }

    /* 余白だけ削除すると、アンチエイリアス部分が残るので決め打ちで少し余分に切り取る */
    const margin = Math.floor(canvas.width / 300) + 1;
    top = top === 0 ? 0 : top + margin;
    bottom = bottom === canvas.height ? canvas.height : bottom - margin;
    left = left === 0 ? 0 : left + margin;
    right = right === canvas.width ? canvas.width : right - margin;

    this.memCanvas2.width = right - left;
    this.memCanvas2.height = bottom - top;

    const context2 = this.memCanvas2.getContext("2d");
    if (context2 == null) {
      return "";
    }

    context2.drawImage(canvas, -left, -top);

    return this.memCanvas2.toDataURL();
  }
}

export const imageCrop = new ImageCrop();
