import React, { FunctionComponentElement } from "react";
import { render } from "react-dom";
import { App } from "./app";

declare const REACT_APP_GOOGLE_AD_CLIENT: string;
declare const REACT_APP_GOOGLE_AD_SLOT: string;
declare const REACT_APP_GOOGLE_AD_SLOT_INFEED: string;
declare const dataLayer: any;
declare const REACT_APP_GOOGLE_ANALYTICS: string;

export const initialize = (component: FunctionComponentElement<any>) => {
  window.onload = () => {
    document.body.addEventListener(
      "drop",
      (e) => {
        e.preventDefault();
      },
      true
    );
    document.body.addEventListener(
      "dragenter",
      (e) => {
        e.preventDefault();
      },
      true
    );
    document.body.addEventListener(
      "dragleave",
      (e) => {
        e.preventDefault();
      },
      true
    );
    document.body.addEventListener(
      "dragover",
      (e) => {
        e.preventDefault();
      },
      true
    );

    const app = document.getElementById("app");
    render(component, app);
  };

  /* ================================== */
  /* 画面いっぱい分の高さを設定しておく */
  /* ================================== */
  const setFillHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  // 画面のサイズ変動があった時に高さを再計算する
  window.addEventListener("resize", setFillHeight);

  // 初期化
  setFillHeight();

  /* アンカー広告を下部に固定 */
  if (
    REACT_APP_GOOGLE_AD_CLIENT !== "" &&
    REACT_APP_GOOGLE_AD_SLOT !== "" &&
    REACT_APP_GOOGLE_AD_SLOT_INFEED !== ""
  ) {
    const adsbygoogle = (window as any).adsbygoogle;
    if (adsbygoogle) {
      adsbygoogle.push({
        google_ad_client: REACT_APP_GOOGLE_AD_CLIENT,
        enable_page_level_ads: true,
        overlays: { bottom: true },
      });
    }
  }

  /* Enable Analytics */
  function gtag(a: string, b: any) {
    dataLayer.push(arguments);
  }

  if (REACT_APP_GOOGLE_ANALYTICS !== "") {
    const w = window as any;
    w.dataLayer = w.dataLayer || [];
    gtag("js", new Date());
    gtag("config", REACT_APP_GOOGLE_ANALYTICS);
    w.dataLayer.push("js", new Date());
    w.dataLayer.push("config", REACT_APP_GOOGLE_ANALYTICS);
  }
};
