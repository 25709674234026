import React from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { IdentifyBrowser, isIOS } from "../../../../IdentifyBrowser";
import { Button } from "../../../components/Button";
import { CardList } from "../CardList";
import { Menu } from "../Menu";

export type PresentationalProps = {
  base64ImageList: Array<string>;

  cardSize: {
    height: number;
    width: number;
  };

  defaultCardSize: {
    height: number;
    width: number;
  };
  defaultCardMargin: number;
  isEnableImageCrop: boolean;

  onHelpButtonClicked: () => void;
  isHelpShown: boolean;
  onDownloadButtonClicked: () => void;
  onCardSizeChanged: (size: { height?: number; width?: number }) => void;
  onCardMarginChanged: (margin: number) => void;
  onIsEnableImageCropChanged: (isEnable: boolean) => void;
  onImageCropRateChanged: (value: number) => void;
  onSetImage: (index: number, base64: string) => void;
};

export const PresentationalComponent = (props: PresentationalProps) => {
  return (
    <StyledView>
      <BrowserConfirmation />
      <HelpButton
        onHelpButtonClicked={props.onHelpButtonClicked}
        isHelpShown={props.isHelpShown}
      />
      <StyledContainer>
        <CardList
          base64ImageList={props.base64ImageList}
          cardSize={props.cardSize}
          onSetImage={props.onSetImage}
        />
        <Menu
          defaultCardSize={props.defaultCardSize}
          defaultCardMargin={props.defaultCardMargin}
          isEnableImageCrop={props.isEnableImageCrop}
          onCardSizeChanged={props.onCardSizeChanged}
          onCardMarginChanged={props.onCardMarginChanged}
          onDownLoadButtonClicked={props.onDownloadButtonClicked}
          onIsEnableImageCropChanged={props.onIsEnableImageCropChanged}
          onImageCropRateChanged={props.onImageCropRateChanged}
        />
      </StyledContainer>
    </StyledView>
  );
};

const BrowserConfirmation = (props: {}) => {
  if (!isIOS() && IdentifyBrowser() === "firefox") {
    return (
      <StyledBrowserConfirmation>
        <StyledConfirmationP>
          Firefox では機能に制限がかかります。
        </StyledConfirmationP>
        <p>最新版の Chrome / Edge / Safari をご利用ください。</p>
      </StyledBrowserConfirmation>
    );
  }

  return <></>;
};

const HelpButton = (props: {
  onHelpButtonClicked: () => void;
  isHelpShown: boolean;
}) => {
  if (props.isHelpShown) {
    return (
      <StyledHelp>
        <Button
          styleSize={{ width: "100%" }}
          styleMargin={{ bottom: "24px" }}
          stylePadding={{
            right: "16px",
            left: "16px",
            top: "8px",
            bottom: "8px",
          }}
          onClicked={props.onHelpButtonClicked}
        >
          <span
            style={{
              display: "inline-block",
              lineHeight: "20px",
              fontSize: "12px",
            }}
          >
            まずはここをクリックして
          </span>
          <span
            style={{
              display: "inline-block",
              lineHeight: "20px",
              fontSize: "12px",
            }}
          >
            使い方をチェック！
          </span>
        </Button>
      </StyledHelp>
    );
  } else {
    return <></>;
  }
};

const StyledBrowserConfirmation = styled.div`
  margin: 0 auto;
  padding: 16px 16px;
  width: 640px;
  max-width: 100%;
  line-height: 20px;
`;

const StyledConfirmationP = styled.p`
  font-weight: bold;
  text-decoration: underline;
  padding-bottom: 8px;
`;

const StyledHelp = styled.div`
  margin: 0 16px;
  padding-top: 16px;
  width: 640px;
  max-width: calc(100% - 32px);

  display: inline-block;
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;

  ${media.lessThan("medium")`
	  display: block;
  `}
`;

const StyledView = styled.div`
  text-align: center;
`;
