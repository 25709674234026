import styled from "styled-components";

export type StyleMarginProps = {
  styleMargin?: {
    top?: string;
    bottom?: string;
    right?: string;
    left?: string;
  };
};

export type StylePaddingProps = {
  stylePadding?: {
    top?: string;
    bottom?: string;
    right?: string;
    left?: string;
  };
};

export type StyleSizeProps = {
  styleSize?: {
    width?: string;
    height?: string;
  };
};

export type StyleProps = StyleMarginProps & StylePaddingProps & StyleSizeProps;

export const StyledDiv = styled.div<StyleProps>`
  height: ${(props) => props.styleSize?.height};
  width: ${(props) => props.styleSize?.width};

  margin-top: ${(props) => props.styleMargin?.top};
  margin-bottom: ${(props) => props.styleMargin?.bottom};
  margin-right: ${(props) => props.styleMargin?.right};
  margin-left: ${(props) => props.styleMargin?.left};

  padding-top: ${(props) => props.stylePadding?.top};
  padding-bottom: ${(props) => props.stylePadding?.bottom};
  padding-right: ${(props) => props.stylePadding?.right};
  padding-left: ${(props) => props.stylePadding?.left};
`;
