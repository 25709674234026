import React from "react";
import { AboutWidget } from "../General/AboutWidget";
import { ArticleListWidget } from "../General/ArticleListWidget";
import { FooterWidget } from "../General/FooterWidget";
import { HeaderWidget } from "../General/HeaderWidget";
import { CardDesignWidget } from "./CardDesignWidget";
import { HelpWidget } from "./HelpWidget";

export type PresentationalProps = {};

export const PresentationalPage = (props: PresentationalProps) => {
  return (
    <>
      <HeaderWidget />
      <CardDesignWidget />
      <HelpWidget />
      <ArticleListWidget />
      <AboutWidget />
      <FooterWidget />
    </>
  );
};
