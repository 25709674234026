import React, { useRef, useState } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { StyledDiv, StyleProps } from "../StyledDiv";

type Props = {
  onDropImage: (base64: string) => void;
  imgUrl: string;
} & StyleProps;

const preventDefault = (e: any) => {
  e.preventDefault();
};

export const PresentationalComponent = (props: Props) => {
  const [dragHoverCounter, setDragHoverCounter] = useState(0);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const onDropImage = (fileList: FileList) => {
    if (fileList == null) {
      return;
    }

    if (fileList.length === 0) {
      console.log("not exist FileBlob");
      return;
    }

    const fileBlob = fileList[0];
    if (fileBlob.type.match(/image/) == null) {
      console.log("not image file");
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(fileBlob); // blob を base64 へ変換し onload を呼び出します

    reader.onload = () => {
      props.onDropImage(reader.result as string);
    };
  };

  const handleFileClick = () => {
    console.log("click");
    hiddenFileInput.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    preventDefault(e);
    if (e.target.files) {
      onDropImage(e.target.files);
    }
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    setDragHoverCounter(0);
    preventDefault(e);
    onDropImage(e.dataTransfer.files);
  };

  const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    setDragHoverCounter(dragHoverCounter + 1);
    preventDefault(e);
  };
  const onDragLeave = (e: any) => {
    setDragHoverCounter(dragHoverCounter - 1);
    preventDefault(e);
  };
  const content =
    props.imgUrl === "" ? <HelpContent /> : <StyledImg src={props.imgUrl} />;

  return (
    <StyledComponent
      styleMargin={props.styleMargin}
      stylePadding={props.stylePadding}
      styleSize={props.styleSize}
      onDrop={onDrop}
      onDragEnter={onDragEnter}
      onDragOver={preventDefault}
      onDragLeave={onDragLeave}
      onClick={handleFileClick}
      isDragHover={dragHoverCounter !== 0}
    >
      {content}
      <input
        className="hidden"
        type="file"
        ref={hiddenFileInput}
        onChange={handleFileChange}
        accept="image/png, image/jpeg"
        style={{ display: "none" }}
      />
    </StyledComponent>
  );
};

const HelpContent = () => {
  return (
    <StyledHelpContent>
      <StyledDragAndDropHelpHeader>
        Drag＆Drop an Image{" "}
      </StyledDragAndDropHelpHeader>
      <StyledDragAndDropHelpText>
        カード画像をドラッグ＆ドロップ
      </StyledDragAndDropHelpText>
      <StyledDragAndDropHelpTextMobile>
        タップして
        <br />
        画像を追加
      </StyledDragAndDropHelpTextMobile>
    </StyledHelpContent>
  );
};

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledComponent = styled(StyledDiv)<{ isDragHover?: boolean }>`
  background-color: rgba(52, 62, 67);
  border: solid
    ${({ isDragHover }) =>
      isDragHover ? `2px rgb(255, 255, 255, 0.8)` : `1px rgb(76, 90, 97)`};

  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;

  user-select: none;

  &:hover {
    cursor: pointer;
  }
`;

const StyledHelpContent = styled.div`
  text-align: center;
`;

const StyledDragAndDropHelpHeader = styled.p`
  font-size: 16px;
  margin-bottom: 14px;
  ${media.lessThan("medium")`
	display:none;
  `}
`;

const StyledDragAndDropHelpText = styled.p`
  font-size: 12px;
  color: rgba(220, 225, 227, 0.8);
  ${media.lessThan("medium")`
	display:none;
  `}
`;

const StyledDragAndDropHelpTextMobile = styled.p`
  font-size: 12px;
  color: rgba(220, 225, 227, 0.8);
  line-height: 16px;

  ${media.greaterThan("medium")`
	display:none;
  `}
`;
