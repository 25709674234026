import * as React from "react";
import styled from "styled-components";
import {
  StyledDiv,
  StyleMarginProps,
  StylePaddingProps,
  StyleSizeProps,
} from "./StyledDiv";

declare const NODE_ENV: string;
declare const REACT_APP_GOOGLE_AD_CLIENT: string;
declare const REACT_APP_GOOGLE_AD_SLOT: string;
declare const REACT_APP_GOOGLE_AD_SLOT_INFEED: string;

type AdsenseWidgetProps = {
  isHorizontal?: boolean;
} & StyleSizeProps &
  StyleMarginProps &
  StylePaddingProps;

interface AdsenseWidgetState {}

export class AdsenseWidget extends React.Component<
  AdsenseWidgetProps,
  AdsenseWidgetState
> {
  constructor(props: AdsenseWidgetProps) {
    super(props);

    this.state = {};
  }

  public componentDidMount() {
    const w = window as any;
    if (w.adsbygoogle && NODE_ENV === "production") {
      w.adsbygoogle.push({});
    }
  }

  render() {
    if (REACT_APP_GOOGLE_AD_CLIENT !== "" && REACT_APP_GOOGLE_AD_SLOT !== "") {
      return (
        <StyledAdsenseBlock {...this.props}>
          {this.props.isHorizontal && (
            <StyledIns
              className="adsbygoogle"
              style={{ display: "block" }}
              data-ad-client={REACT_APP_GOOGLE_AD_CLIENT}
              data-ad-slot={REACT_APP_GOOGLE_AD_SLOT}
              data-ad-format="horizontal"
              data-full-width-responsive="false"
            />
          )}
          {!this.props.isHorizontal && (
            <StyledIns
              className="adsbygoogle"
              style={{ display: "block" }}
              data-ad-client={REACT_APP_GOOGLE_AD_CLIENT}
              data-ad-slot={REACT_APP_GOOGLE_AD_SLOT}
              data-ad-format="rectangle,horizontal"
              data-full-width-responsive="true"
            ></StyledIns>
          )}
        </StyledAdsenseBlock>
      );
    }
    return <div />;
  }
}

const StyledAdsenseBlock = styled(StyledDiv)``;

const StyledIns = styled.ins`
  height: "90px";
`;
