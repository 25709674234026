import React, { useMemo, useState } from "react";
import { PrintDataCreator } from "../../../../CreatePdf";
import { isAndroid } from "../../../../IdentifyBrowser";
import { imageCrop } from "../../../../ImageCrop";
import { PresentationalComponent, PresentationalProps } from "./presentational";

type Props = {};
const defaultCardSize = {
  width: 59,
  height: 86,
};
const defaultCardMargin = 0;

const defaultBase64ImageList = ["", "", "", "", "", "", "", "", ""];

const originalBase64ImageList = ["", "", "", "", "", "", "", "", ""];
const cropedBase64ImageList = ["", "", "", "", "", "", "", "", ""];

export const CardDesignWidget = (props: Props) => {
  const [cardSizeWidth, setCardSizeWidth] = useState(defaultCardSize.width);
  const [cardSizeHeight, setCardSizeHeight] = useState(defaultCardSize.height);
  const cardSize = { height: cardSizeHeight, width: cardSizeWidth };
  const [cardMargin, setCardMargin] = useState(defaultCardMargin);
  const [base64ImageList, setBase64ImageList] = useState(
    defaultBase64ImageList
  );
  const [isEnableImageCrop, setIsEnableImageCrop] = useState(false);
  const [imageCropRate, setImageCropRate] = useState(2);

  const onCardSizeChanged = (size: { height?: number; width?: number }) => {
    if (size.height) {
      setCardSizeHeight(size.height);
    }
    if (size.width) {
      setCardSizeWidth(size.width);
    }
  };
  const onCardMarginChanged = (margin: number) => {
    setCardMargin(margin);
  };

  const onSetImage = async (index: number, base64: string) => {
    originalBase64ImageList[index] = base64;
    cropedBase64ImageList[index] = await imageCrop.crop(
      base64,
      imageCropRate,
      true
    );

    const imageList = [...base64ImageList];
    if (isEnableImageCrop) {
      imageList[index] = cropedBase64ImageList[index];
    } else {
      imageList[index] = originalBase64ImageList[index];
    }
    setBase64ImageList(imageList);
  };

  const createPDF = async () => {
    const printer = new PrintDataCreator();
    const imageList = [...base64ImageList];

    /* 余白の切り抜きが有効な場合、本番用の画像を作成する */
    if (isEnableImageCrop) {
      for (let index = 0; index < originalBase64ImageList.length; index++) {
        const base64 = originalBase64ImageList[index];
        if (base64 == "") {
          continue;
        }
        imageList[index] = await imageCrop.crop(base64, imageCropRate);
      }
    }

    const pdfData = await printer.createProxyCardPrintableSheet(
      imageList,
      {
        widthMM: cardSize.width,
        heightMM: cardSize.height,
      },
      cardMargin
    );
    const fileURL = window.URL.createObjectURL(pdfData);

    return fileURL;
  };

  const onDownloadButtonClicked = async () => {
    if (isAndroid()) {
      const pdfFileURL = await createPDF();
      window.open(pdfFileURL);
      return;
    }

    /* iOS で別タブで開くための参考 URL */
    /* https://blog.officekoma.co.jp/2021/03/windowsopeniossafari.html */
    const windowReference = window.open();
    if (windowReference) {
      windowReference.location = await createPDF();
    }
  };

  const onHelpButtonClicked = () => {
    const howtodiv = document.getElementById("howtouse");
    window.scroll({
      top: howtodiv?.offsetTop,
      behavior: "smooth",
    });
    localStorage.setItem("helpShownVersion", "1.0.0");
  };

  const isHelpShown = useMemo(() => {
    const helpShownVersion = localStorage.getItem("helpShownVersion");
    return helpShownVersion !== "1.0.0";
  }, []);

  const onIsEnableImageCropChanged = async (isEnable: boolean) => {
    setIsEnableImageCrop(isEnable);

    if (isEnable) {
      const imageList = [...cropedBase64ImageList];
      setBase64ImageList(imageList);
    } else {
      const imageList = [...originalBase64ImageList];
      setBase64ImageList(imageList);
    }
  };

  const onImageCropRateChanged = async (value: number) => {
    if (value == imageCropRate) {
      return;
    }

    setImageCropRate(value);

    for (let index = 0; index < originalBase64ImageList.length; index++) {
      const base64 = originalBase64ImageList[index];
      if (base64 == "") {
        continue;
      }
      cropedBase64ImageList[index] = await imageCrop.crop(base64, value, true);
    }

    if (isEnableImageCrop) {
      const imageList = [...cropedBase64ImageList];
      setBase64ImageList(imageList);
    }
  };

  const presentationalProps: PresentationalProps = {
    base64ImageList: base64ImageList,
    cardSize: cardSize,
    defaultCardSize: cardSize,
    defaultCardMargin: cardMargin,
    isHelpShown: isHelpShown,
    isEnableImageCrop: isEnableImageCrop,
    onHelpButtonClicked: onHelpButtonClicked,
    onDownloadButtonClicked: onDownloadButtonClicked,
    onCardSizeChanged: onCardSizeChanged,
    onCardMarginChanged: onCardMarginChanged,
    onIsEnableImageCropChanged: onIsEnableImageCropChanged,
    onImageCropRateChanged: onImageCropRateChanged,
    onSetImage: onSetImage,
  };
  return <PresentationalComponent {...presentationalProps} />;
};
