import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import {
  StyleMarginProps,
  StylePaddingProps,
  StyleSizeProps,
} from "./StyledDiv";
import { StyledInput } from "./StyledInput";

type Props = {
  onTextChanged?: (text: string) => void;

  defaultText?: string;
  placeholder?: string;
} & StyleMarginProps &
  StylePaddingProps &
  StyleSizeProps;

const createChangeHandler = (onTextChanged?: (text: string) => void) => {
  if (onTextChanged == null) {
    return undefined;
  }
  return (event: React.ChangeEvent<HTMLInputElement>) => {
    const text: string = event.target.value;
    onTextChanged(text);
  };
};

export const TextBox: React.FC<Props> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (props.onTextChanged) {
      props.onTextChanged(inputRef.current?.value ?? "");
    }
  });

  return (
    <StyledTextBox
      defaultValue={props.defaultText}
      placeholder={props.placeholder}
      onInput={createChangeHandler(props.onTextChanged)}
      styleSize={props.styleSize}
      styleMargin={props.styleMargin}
      stylePadding={props.stylePadding}
      ref={inputRef}
    ></StyledTextBox>
  );
};

const StyledTextBox = styled(StyledInput)`
  background-color: transparent;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  outline: none;

  display: inline-block;
  border: none;
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
  margin-bottom: 1px;
  text-align: center;

  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);

  transition: 0.3s;

  &:focus {
    outline: 0;
    border-bottom: solid 2px rgba(255, 255, 255, 0.8);
    margin-bottom: 0px;
  }
`;
