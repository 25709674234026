import React from "react";
import { PresentationalComponent } from "./presentational";

type Props = {
  base64ImageList: Array<string>;
  onSetImage: (index: number, base64: string) => void;

  cardSize: {
    width: number;
    height: number;
  };
};

export const CardList = (props: Props) => {
  return <PresentationalComponent {...props} />;
};
