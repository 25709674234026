import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import media from "styled-media-query";
import { AdsenseWidget } from "../../../components/AdsenseWidget";
import { Button } from "../../../components/Button";
import { OnOffWidget } from "../../../components/OnOffWidget";
import { Slider } from "../../../components/Slider";
import { TextBox } from "../../../components/TextBox";

export type PresentationalProps = {
  onHeightChanged: (text: string) => void;
  onWidthChanged: (text: string) => void;
  onDownloadClicked: () => void;
  onCardMarginChanged: (text: string) => void;
  onIsEnableImageCropChanged: (isEnable: boolean) => void;
  onImageCropsRateChanged: (value: number) => void;

  defaultCardSize: {
    width: number;
    height: number;
  };
  defaultCardMargin: number;
  isEnableImageCrop: boolean;
};

export const PresentationalComponent = (props: PresentationalProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <StyledComponent>
      {isMobile && (
        <AdsenseWidget
          stylePadding={{ top: "24px", bottom: "64px" }}
          isHorizontal
        />
      )}
      <CardSizeWidget
        defaultCardSize={props.defaultCardSize}
        onHeightChanged={props.onHeightChanged}
        onWidthChanged={props.onWidthChanged}
      />
      <ImageCropWidget
        isEnableImageCrop={props.isEnableImageCrop}
        onIsEnableImageCropChanged={props.onIsEnableImageCropChanged}
        onImageCropsRateChanged={props.onImageCropsRateChanged}
      />
      <CardMarginWidget
        defaultCardMargin={props.defaultCardMargin}
        onCardMraginChanged={props.onCardMarginChanged}
      />
      <StyledHeader2>Print</StyledHeader2>
      <StyledContent>
        <Button
          styleSize={{ width: "100%" }}
          onClicked={props.onDownloadClicked}
        >
          PDF Download
        </Button>
      </StyledContent>
      {isMobile && (
        <AdsenseWidget stylePadding={{ top: "24px", bottom: "64px" }} />
      )}
      {isMobile === false && (
        <AdsenseWidget stylePadding={{ top: "16px", bottom: "48px" }} />
      )}
      {isMobile === false && (
        <AdsenseWidget stylePadding={{ bottom: "48px" }} />
      )}
    </StyledComponent>
  );
};

/* ============== */
/* CardSizeWidget */
/* ============== */
type CardSizeWidgetProps = {
  onHeightChanged: (text: string) => void;
  onWidthChanged: (text: string) => void;

  defaultCardSize: {
    width: number;
    height: number;
  };
};

type SelectSizeType = "small" | "standard" | "etc";

const CardSizeWidget = (props: CardSizeWidgetProps) => {
  const [selectSizeType, setSelectSizeType] = useState<SelectSizeType>("small");

  const onChangedSelectSize = (type: SelectSizeType) => {
    return (isOn: boolean) => {
      if (isOn) {
        setSelectSizeType(type);

        switch (type) {
          case "standard":
            props.onWidthChanged("63");
            props.onHeightChanged("88");
            break;
          case "small":
            props.onWidthChanged("59");
            props.onHeightChanged("86");
            break;
          case "etc":
            break;
        }
      }
    };
  };

  return (
    <>
      <StyledHeader2>Card Size</StyledHeader2>
      <StyledContent>
        <StyledSizeRow>
          <OnOffWidget
            isOn={selectSizeType === "small"}
            onStateChanged={onChangedSelectSize("small")}
            styleSize={{ width: "32px", height: "16px" }}
          />
          <div>
            <p>59mm x 86mm</p>
          </div>
        </StyledSizeRow>
        <StyledSizeRow>
          <OnOffWidget
            isOn={selectSizeType === "standard"}
            onStateChanged={onChangedSelectSize("standard")}
            styleSize={{ width: "32px", height: "16px" }}
          />
          <div>
            <p>63mm x 88mm</p>
          </div>
        </StyledSizeRow>
        <StyledSizeRow>
          <OnOffWidget
            isOn={selectSizeType === "etc"}
            onStateChanged={onChangedSelectSize("etc")}
            styleSize={{ width: "32px", height: "16px" }}
          />
          <div>その他</div>
        </StyledSizeRow>

        {selectSizeType === "etc" ? (
          <StyledSizeRow>
            <StyledSizeCategory>横の長さ</StyledSizeCategory>
            <StyledSizeNumber>
              <TextBox
                onTextChanged={props.onWidthChanged}
                defaultText={props.defaultCardSize.width.toString()}
                styleSize={{ width: "60px" }}
              ></TextBox>
              <StyledSizeUnit>mm</StyledSizeUnit>
            </StyledSizeNumber>
          </StyledSizeRow>
        ) : null}
        {selectSizeType === "etc" ? (
          <StyledSizeRow>
            <StyledSizeCategory>縦の長さ</StyledSizeCategory>
            <StyledSizeNumber>
              <TextBox
                onTextChanged={props.onHeightChanged}
                defaultText={props.defaultCardSize.height.toString()}
                styleSize={{ width: "60px" }}
              ></TextBox>
              <StyledSizeUnit>mm</StyledSizeUnit>
            </StyledSizeNumber>
          </StyledSizeRow>
        ) : null}
      </StyledContent>
    </>
  );
};

/* ================ */
/* CardMarginWidget */
/* ================ */
type CardMarginWidgetProps = {
  onCardMraginChanged: (text: string) => void;
  defaultCardMargin: number;
};

const CardMarginWidget = (props: CardMarginWidgetProps) => {
  return (
    <>
      <StyledHeader2>Card Margin</StyledHeader2>
      <StyledContent>
        <StyledSizeRow>
          <StyledSizeCategory>カードの間隔</StyledSizeCategory>
          <StyledSizeNumber>
            <TextBox
              onTextChanged={props.onCardMraginChanged}
              defaultText={props.defaultCardMargin.toString()}
              styleSize={{ width: "60px" }}
            ></TextBox>
            <StyledSizeUnit>mm</StyledSizeUnit>
          </StyledSizeNumber>
        </StyledSizeRow>
      </StyledContent>
    </>
  );
};

type ImageCropWidgetProps = {
  isEnableImageCrop: boolean;
  onIsEnableImageCropChanged: (isEnable: boolean) => void;
  onImageCropsRateChanged: (value: number) => void;
};

type ImageCropsRate = number;
const ImageCropWidget = (props: ImageCropWidgetProps) => {
  const [imageCropsRate, setImageCropsRate] = useState<ImageCropsRate>(2);

  const onImageCropsRateChanged = (value: number) => {
    setImageCropsRate(value);
    props.onImageCropsRateChanged(value);
  };

  const rateString: string =
    imageCropsRate == 0
      ? "最小"
      : imageCropsRate == 1
      ? "小"
      : imageCropsRate == 2
      ? "中"
      : imageCropsRate == 3
      ? "大"
      : imageCropsRate == 4
      ? "最大"
      : "NONE";

  return (
    <>
      <StyledHeader2>Image Crop</StyledHeader2>
      <StyledContent>
        <StyledSizeRow>
          <OnOffWidget
            isOn={props.isEnableImageCrop}
            onStateChanged={props.onIsEnableImageCropChanged}
            styleSize={{ width: "32px", height: "16px" }}
          />
          <div>
            <p>余白の切り抜き</p>
          </div>
        </StyledSizeRow>
        <StyledSizeRow>
          <div>
            <p>強さ</p>
          </div>
          <div>
            <p>{rateString}</p>
          </div>
        </StyledSizeRow>
        <StyledSizeRow>
          <Slider
            defaultValue={2}
            minValue={0}
            maxValue={4}
            step={1}
            onValueChanged={onImageCropsRateChanged}
          ></Slider>
        </StyledSizeRow>
      </StyledContent>
    </>
  );
};

/* ===== */
/* Style */
/* ===== */
const StyledSizeRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const StyledSizeCategory = styled.div`
  margin-right: 16px;
  text-align: left;
`;

const StyledSizeNumber = styled.div`
  text-align: right;
`;

const StyledSizeUnit = styled.div`
  display: inline-block;
  margin-left: 8px;
`;

const StyledComponent = styled.div`
  width: 320px;
  margin-top: 24px;
  margin-left: 24px;
  margin-right: 16px;

  ${media.lessThan("medium")`
	  margin: 0 auto;
	  width: 100%;
	  padding: 32px 16px;
  `}
`;

const StyledHeader2 = styled.h2`
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  margin-bottom: 24px;
`;
const StyledContent = styled.div`
  text-align: center;
  margin-bottom: 48px;
  padding-left: 16px;
`;
