import React from "react";
import styled from "styled-components";
import {
  StyledDiv,
  StyleMarginProps,
  StylePaddingProps,
  StyleSizeProps,
} from "../StyledDiv";

export type Props = {
  onStateChanged?: (isOn: boolean) => void;
  isOn: boolean;
} & StyleMarginProps &
  StylePaddingProps &
  StyleSizeProps;

export const OnOffWidget: React.FC<Props> = (props) => {
  const onClicked = () => {
    if (props.onStateChanged) {
      props.onStateChanged(!props.isOn);
    }
  };

  return (
    <StyledOnOffWidget
      stylePadding={props.stylePadding}
      styleMargin={props.styleMargin}
      styleSize={props.styleSize}
      isOn={props.isOn}
      onClick={onClicked}
    ></StyledOnOffWidget>
  );
};

const StyledOnOffWidget = styled(StyledDiv)<{ isOn: boolean }>`
  display: inline-block;
  border-radius: 50px;

  border: solid 1px rgba(255, 255, 255, 0.3);

  transition: 0.3s, transform 0.1s;
  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
    cursor: pointer;
  }
  &:active {
    transform: translateY(1px);
  }
  ${({ isOn }) => (isOn ? "background-color: rgba(255, 255, 255, 0.3);" : "")}

  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: -1px;
    ${({ isOn }) => (isOn ? "right : -1px;" : "left: -1px;")}
    height: ${(props) => props.styleSize?.height};
    width: ${(props) => props.styleSize?.height};

    border-radius: 50px;
    background-color: ${({ isOn }) =>
      isOn ? "rgba(255, 255, 255, 0.8)" : "rgba(255, 255, 255, 0.3)"};
  }
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
`;
