import React from "react";
import styled from "styled-components";

export type PresentationalProps = {};

export const PresentationalComponent = (props: PresentationalProps) => {
  return (
    <>
      <header id="header">
        <div className="title">
          <a href="/">
            <StyledLogoSvg src="/img/logo.svg" />
          </a>
          <span className="subtitle-text">
            トレーディングカードの印刷データを作成できます。
          </span>
        </div>

        <div className="help">
          <a href="/#howtouse">
            <div className="button">使い方はこちら</div>
          </a>
        </div>
      </header>
      <div id="header-padding"></div>
    </>
  );
};

const StyledLogoSvg = styled.img`
  height: 20px;
  display: inline-block;
  padding-right: 16px;
`;
