import * as PDFMake from "pdfmake/build/pdfmake";
import { Content, TDocumentDefinitions } from "pdfmake/interfaces";

export class PrintDataCreator {
  private memCanvas: HTMLCanvasElement;
  private marginCanvas: HTMLCanvasElement;
  private marginImage: string;

  constructor() {
    this.memCanvas = document.createElement("canvas");
    this.memCanvas.height = 860;
    this.memCanvas.width = 590;

    this.marginCanvas = document.createElement("canvas");
    this.marginCanvas.height = 1;
    this.marginCanvas.width = 1;
    this.marginImage = this.marginCanvas.toDataURL();
  }

  private setCardSize = (widthMM: number, heightMM: number) => {
    /* 300dpi 相当 */
    this.memCanvas.height = heightMM * 12;
    this.memCanvas.width = widthMM * 12;
  };
  private convertImage = (src: string): Promise<string> => {
    return new Promise((resolve) => {
      const context = this.memCanvas.getContext("2d");
      if (!context) {
        resolve("");
        return;
      }

      const chara = new Image();
      chara.src = src;
      chara.onload = () => {
        const imageWidth = chara.width;
        const imageHeight = chara.height;

        const rateWidth = this.memCanvas.width / imageWidth;
        const rateHeight = this.memCanvas.height / imageHeight;
        const rate = Math.max(rateWidth, rateHeight);

        const transformedWidth = imageWidth * rate;
        const transformedHeight = imageHeight * rate;
        const positionX = (this.memCanvas.width - transformedWidth) / 2;
        const positionY = (this.memCanvas.height - transformedHeight) / 2;

        context.drawImage(
          chara,
          positionX,
          positionY,
          transformedWidth,
          transformedHeight
        );
        resolve(this.memCanvas.toDataURL("image/jpeg", 0.99));
      };
      chara.onerror = () => {
        resolve("");
      };
    });
  };

  private createDummyImage = (): string => {
    const context = this.memCanvas.getContext("2d");
    if (!context) {
      return "";
    }

    context.fillStyle = "rgb(60, 60, 60)";
    context.fillRect(0, 0, this.memCanvas.width, this.memCanvas.height);

    context.fillStyle = "rgb(255, 255, 255)";
    context.fillRect(1, 1, this.memCanvas.width - 2, this.memCanvas.height - 2);
    return this.memCanvas.toDataURL();
  };

  createProxyCardPrintableSheet = async (
    cardImageList: Array<string>,
    cardSize: { widthMM: number; heightMM: number },
    cardMargin: number
  ): Promise<Blob> => {
    this.setCardSize(cardSize.widthMM, cardSize.heightMM);

    const docDefinition = await this.createDocDefinition(
      cardImageList,
      cardSize.widthMM,
      cardMargin
    );

    return new Promise((resolve, reject) => {
      PDFMake.createPdf(
        docDefinition,
        undefined,
        undefined,
        PDFMake.vfs
      ).getBlob((result) => {
        resolve(result);
      });
    });
  };

  private createDocDefinition = async (
    cardImageList: Array<string>,
    cardWidthMM: number,
    cardMarginMM: number
  ) => {
    const widthPt = PrintDataCreator.mm2pt(cardWidthMM);
    const cardMargin = PrintDataCreator.mm2pt(cardMarginMM);
    const pageMargin = PrintDataCreator.mm2pt(
      (210 - cardWidthMM * 3 - cardMarginMM * 2) / 2
    );

    const isEnableCardMargin = cardMargin <= 0 ? false : true;

    const transformedCardImageList: Array<string> = [];
    for (let index = 0; index < cardImageList.length; index++) {
      const cardImage = cardImageList[index];
      const transformedCardImage = await this.convertImage(cardImage);
      if (transformedCardImage !== "") {
        transformedCardImageList.push(transformedCardImage);
      } else {
        transformedCardImageList.push(this.createDummyImage());
      }
    }

    const content: Content = [];
    content.push({
      columns: [
        {
          image: transformedCardImageList[0],
          width: widthPt,
        },
        {
          text: "",
          width: cardMargin,
        },
        {
          image: transformedCardImageList[1],
          width: widthPt,
        },
        {
          text: "",
          width: cardMargin,
        },
        {
          image: transformedCardImageList[2],
          width: widthPt,
        },
      ],
      alignment: "center",
    });

    if (isEnableCardMargin) {
      content.push({
        image: this.marginImage,
        height: cardMargin,
      });
    }

    content.push({
      columns: [
        {
          image: transformedCardImageList[3],
          width: widthPt,
        },
        {
          text: "",
          width: cardMargin,
        },
        {
          image: transformedCardImageList[4],
          width: widthPt,
        },
        {
          text: "",
          width: cardMargin,
        },
        {
          image: transformedCardImageList[5],
          width: widthPt,
        },
      ],
      alignment: "center",
    });

    if (isEnableCardMargin) {
      content.push({
        image: this.marginImage,
        height: cardMargin,
      });
    }

    content.push({
      columns: [
        {
          image: transformedCardImageList[6],
          width: widthPt,
        },
        {
          text: "",
          width: cardMargin,
        },
        {
          image: transformedCardImageList[7],
          width: widthPt,
        },
        {
          text: "",
          width: cardMargin,
        },
        {
          image: transformedCardImageList[8],
          width: widthPt,
        },
      ],
      alignment: "center",
    });

    // pdf設定
    const docDefinition: TDocumentDefinitions = {
      pageSize: "A4",
      pageMargins: pageMargin,
      content: content,
    };

    return docDefinition;
  };
  private static readonly MM2PT = 1 / 0.35278;
  // mmからmakepdf単位系への変換
  static mm2pt = (mm: number) => {
    return mm * PrintDataCreator.MM2PT;
  };
}
