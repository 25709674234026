import React, { useState } from "react";
import { Base64Converter } from "../../../Base64Converter";
import { StyleProps } from "../StyledDiv";
import { PresentationalComponent } from "./presentational";

type Props = {
  base64Image: string;
  onSetImg: (base64: string) => void;
} & StyleProps;

export const CardWidget = (props: Props) => {
  const onDrop = (base64: string) => {
    if (props.onSetImg) {
      props.onSetImg(base64);
    }
  };

  return (
    <PresentationalComponent
      styleMargin={props.styleMargin}
      styleSize={props.styleSize}
      stylePadding={props.stylePadding}
      imgUrl={props.base64Image}
      onDropImage={onDrop}
    />
  );
};
