import React from "react";

export type PresentationalProps = {};

export const PresentationalComponent = (props: PresentationalProps) => {
  return (
    <footer>
      <p>Copyright © 2022-2022 nyankobass All rights rserved.</p>
    </footer>
  );
};
