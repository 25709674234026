import React from "react";

export type PresentationalProps = {};

export const PresentationalComponent = (props: PresentationalProps) => {
  return (
    <div className="about">
      <div className="about-container">
        <h2>プロキシカードデザインとは</h2>
        <p>
          プロキシカードデザインはTCGの印刷データを簡単に作成することができるwebアプリケーションです。
        </p>
        <p>
          ポケモンカードや遊戯王、デュエルマスターズ、バトルスピリッツ、ヴァイスシュヴァルツ、ヴァンガード、MTGなど様々なTCGに対応。
        </p>
        <p>
          用意した画像をドラッグ＆ドロップするだけで簡単に印刷データが作成できます。
        </p>
        <p>
          作成したデータはご家庭のプリンターやコンビニプリントを活用して印刷できます。
        </p>
      </div>
    </div>
  );
};
