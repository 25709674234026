import React from "react";
import styled from "styled-components";
import {
  StyledDiv,
  StyleMarginProps,
  StylePaddingProps,
  StyleSizeProps,
} from "./StyledDiv";

export type Props = {
  children?: React.ReactNode;
  onClicked?: () => void;
} & StyleMarginProps &
  StylePaddingProps &
  StyleSizeProps;

export const Button: React.FC<Props> = (props) => {
  return (
    <StyledButton
      onClick={props.onClicked}
      styleSize={props.styleSize}
      styleMargin={props.styleMargin}
      stylePadding={{
        top: props.stylePadding?.top ?? "8px",
        bottom: props.stylePadding?.bottom ?? "8px",
        left: props.stylePadding?.left ?? "8px",
        right: props.stylePadding?.right ?? "8px",
      }}
    >
      <StyledContainer>
        <p>{props.children}</p>
      </StyledContainer>
    </StyledButton>
  );
};

const StyledButton = styled(StyledDiv)<{}>`
  display: inline-block;
  border-radius: 50px;
  text-align: center;

  font-size: 14px;

  border: solid 1px rgba(255, 255, 255, 0.3);

  transition: 0.3s, transform 0.1s;
  &:active {
    transform: translateY(1px);
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
    color: rgb(45, 53, 58);
    cursor: pointer;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
`;
