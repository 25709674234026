import React from "react";
import styled from "styled-components";

export type PresentationalProps = {};

export const PresentationalComponent = (props: PresentationalProps) => {
  return (
    <StyledArticleListWidget>
      <StyledArticleListTitle>記事一覧</StyledArticleListTitle>
      <StyledContainer>
        <ArticleCard
          title="【デュエルマスターズ】プロキシカードの作り方【スマホ対応】"
          url="/article/HC7Q4NBR.html"
          imgUrl="/img/blog/card_HC7Q4NBR.jpg"
        ></ArticleCard>
      </StyledContainer>
    </StyledArticleListWidget>
  );
};

type ArticleCardProps = {
  title: string;
  url: string;
  imgUrl: string;
};
const ArticleCard = (props: ArticleCardProps) => {
  return (
    <a href={props.url}>
      <StyledArticleCardContainer>
        <StyledArticleCardImage>
          <img style={{ width: "100%" }} src={props.imgUrl}></img>
        </StyledArticleCardImage>
        <StyledArticleCardTitle>{props.title}</StyledArticleCardTitle>
      </StyledArticleCardContainer>
    </a>
  );
};

const StyledArticleCardContainer = styled.div`
  display: flex;
  &:hover {
    text-decoration: underline;
  }

  margin-bottom: 32px;
`;
const StyledArticleCardImage = styled.div`
  width: 35%;
  flex-shrink: 0;
  margin-right: 8px;
`;

const StyledArticleCardTitle = styled.div`
  text-align: left;
  line-height: 1.5rem;
  font-size: 14px;
`;

const StyledArticleListWidget = styled.div`
  padding: 40px 16px;
  text-align: center;
`;

const StyledContainer = styled.div`
  max-width: 480px;
  margin: 0 auto;
`;

const StyledArticleListTitle = styled.h2`
  max-width: 640px;
  margin: 0 auto;

  padding-bottom: 8px;

  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px rgba(255, 255, 255, 0.5) solid;
  margin-bottom: 40px;
`;
