import React from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { CardWidget } from "../../../components/CardWidget";

type Props = {
  base64ImageList: Array<string>;
  onSetImage: (index: number, base64: string) => void;

  cardSize: {
    width: number;
    height: number;
  };
};

const Cell = (props: Props & { index: number }) => {
  const onSetImage = (base64: string) => {
    props.onSetImage(props.index, base64);
  };
  const base64Image = props.base64ImageList[props.index];

  return (
    <StyledCell cardSize={{ width: 59, height: 86 }}>
      <StyledAbsoluteCell>
        <StyledCellContent cardSize={props.cardSize}>
          <CardWidget
            base64Image={base64Image}
            onSetImg={onSetImage}
            styleSize={{ width: "100%", height: "100%" }}
          />
        </StyledCellContent>
      </StyledAbsoluteCell>
    </StyledCell>
  );
};

export const PresentationalComponent = (props: Props) => {
  return (
    <StyledComponent>
      <StyledRow>
        <Cell {...props} index={0} />
        <Cell {...props} index={1} />
        <Cell {...props} index={2} />
      </StyledRow>
      <StyledRow>
        <Cell {...props} index={3} />
        <Cell {...props} index={4} />
        <Cell {...props} index={5} />
      </StyledRow>
      <StyledRow>
        <Cell {...props} index={6} />
        <Cell {...props} index={7} />
        <Cell {...props} index={8} />
      </StyledRow>
    </StyledComponent>
  );
};

const StyledAbsoluteCell = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const StyledComponent = styled.div`
  width: 720px;

  ${media.lessThan("medium")`
	  width: 100%;
	  padding: 12px;
  `}
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledCell = styled.div<{
  cardSize: {
    width: number;
    height: number;
  };
}>`
  flex-grow: 2;

  margin: 8px;
  ${media.lessThan("medium")`
	  margin: 4px;
  `}

  position: relative;
  &:before {
    content: "";
    display: block;
    padding-top: ${({ cardSize }) => {
      const ratio = (cardSize.height / cardSize.width) * 100;
      if (ratio > 200) {
        return 200;
      }
      if (ratio < 100) {
        return 100;
      }
      if (isNaN(cardSize.height)) {
        return 100;
      }
      if (isNaN(ratio)) {
        return 200;
      }
      return ratio;
    }}%;
  }
`;

const clipSize = (cardSize: { width: number; height: number }) => {
  const ratio = (cardSize.height / cardSize.width) * 100;
  const clipedCardSize: { width: number; height: number } = { ...cardSize };
  if (ratio > 200) {
    clipedCardSize.height = 2;
    clipedCardSize.width = 1;
  }
  if (ratio < 100) {
    clipedCardSize.height = 1;
    clipedCardSize.width = 1;
  }
  if (isNaN(clipedCardSize.height)) {
    clipedCardSize.height = 1;
    clipedCardSize.width = 1;
  }
  if (isNaN(ratio)) {
    clipedCardSize.height = 2;
    clipedCardSize.width = 1;
  }

  return clipedCardSize;
};

const StyledCellContent = styled.div<{
  cardSize: {
    width: number;
    height: number;
  };
}>`
  margin: 0 auto;
  ${({ cardSize }) => {
    cardSize = clipSize(cardSize);
    if (59 / 86 > cardSize.width / cardSize.height) {
      return `
			width: ${((8600 / cardSize.height) * cardSize.width) / 56}%;
			height: 100%;
		`;
    } else {
      const height = (5900 * cardSize.height) / (86 * cardSize.width);
      const margin = (8600 - 86 * height) / 2 / 56;
      return `
			height: ${height}%;
			width: 100%;
			margin-top: ${margin}%;
		`;
    }
  }}
`;
