import React from "react";
import { useMediaQuery } from "react-responsive";

export type PresentationalProps = {};

export const PresentationalComponent = (props: PresentationalProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <div id="howtouse">
      <h2 className="title">プロキシカードの作り方</h2>
      {isMobile === true ? <MobileHelpWidget /> : <DesktopHelpWidget />}
      <a href="/#top">
        <div className="button">プロキシカードデザインを使ってみる</div>
      </a>
    </div>
  );
};

const DesktopHelpWidget = () => {
  return (
    <>
      <div className="howtouse-container">
        <h2>カード画像を登録しよう</h2>
        <div>
          <p>
            Webサイトから画像をドラッグ＆ドロップして登録することができます。
          </p>
          <p>
            <img src="img/howtouse002.gif" loading="lazy" />
          </p>
        </div>

        <div>
          <p>
            ローカルファイルをドラッグ＆ドロップしても登録することができます。
          </p>
          <p>
            <img src="img/howtouse001.gif" loading="lazy" />
          </p>
        </div>

        <div>
          <p>一度登録した画像から別のスロットに登録することもできます。</p>
          <p>
            <img src="img/howtouse003.gif" loading="lazy" />
          </p>
        </div>
      </div>

      <div className="howtouse-container">
        <h2>カードのサイズを入力しよう</h2>
        <p>入力されたカードサイズで印刷データが作成できます。</p>
        <p>
          <img src="img/howtouse004.gif" loading="lazy" />
        </p>
      </div>

      <div className="howtouse-container">
        <h2>印刷データを作成しよう</h2>
        <p>
          PDF Download ボタンをクリックして印刷データをダウンロードできます。
        </p>
        <p style={{ textAlign: "center" }}>
          <img src="img/howtouse005.gif" loading="lazy" />
        </p>
      </div>
    </>
  );
};

const MobileHelpWidget = () => {
  return (
    <>
      <div className="howtouse-container">
        <h2>カード画像を登録しよう</h2>
        <p>
          「タップして画像を追加」をタップして、フォトライブラリから画像を登録できます。
        </p>
        <p style={{ textAlign: "center" }}>
          <video loop muted autoPlay playsInline style={{ width: "80%" }}>
            <source src="img/howtousemobile001.mp4" type="video/mp4" />
          </video>
        </p>
      </div>

      <div className="howtouse-container">
        <h2>カードのサイズを入力しよう</h2>
        <p>印刷したいカードのサイズを選択できます。</p>
        <p style={{ textAlign: "center" }}>
          <video loop muted autoPlay playsInline style={{ width: "80%" }}>
            <source src="img/howtousemobile002.mp4" type="video/mp4" />
          </video>
        </p>
      </div>

      <div className="howtouse-container">
        <h2>印刷データを作成しよう</h2>
        <p>
          PDF Download ボタンをクリックして印刷データをダウンロードできます。
        </p>
        <p style={{ textAlign: "center" }}>
          <video loop muted autoPlay playsInline style={{ width: "80%" }}>
            <source src="img/howtousemobile003.mp4" type="video/mp4" />
          </video>
        </p>
      </div>
    </>
  );
};
