import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import {
  StyleMarginProps,
  StylePaddingProps,
  StyleSizeProps,
} from "./StyledDiv";
import { StyledInput } from "./StyledInput";

type Props = {
  onValueChanged?: (value: number) => void;

  defaultValue?: number;
  minValue?: number;
  maxValue?: number;
  step?: number;
} & StyleMarginProps &
  StylePaddingProps &
  StyleSizeProps;

const createChangeHandler = (onValueChanged?: (value: number) => void) => {
  if (onValueChanged == null) {
    return undefined;
  }
  return (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: number = event.target.valueAsNumber;
    onValueChanged(value);
  };
};

export const Slider: React.FC<Props> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (props.onValueChanged) {
      props.onValueChanged(inputRef.current?.valueAsNumber ?? 0);
    }
  });

  return (
    <StyledInputRange
      defaultValue={props.defaultValue}
      onChange={createChangeHandler(props.onValueChanged)}
      styleSize={props.styleSize}
      styleMargin={props.styleMargin}
      stylePadding={props.stylePadding}
      ref={inputRef}
      type="range"
      min={props.minValue}
      max={props.maxValue}
      step={props.step}
    ></StyledInputRange>
  );
};

const StyledInputRange = styled(StyledInput)`
  background-color: transparent;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  outline: none;

  background-color: rgba(255, 255, 255, 0.3);
  height: 2px;
  width: 100%;
  border-radius: 6px;

  &:focus,
  &:active {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    position: relative;
    width: 18px;
    height: 18px;
    display: block;
    border: 1px solid #717171;
    background-color: #e3e7e9;
    border-radius: 50%;
    -webkit-border-radius: 50%;
  }
`;
